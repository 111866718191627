import React from "react";

export const LayoutControls = ({
  solo,
  setSolo,
  videoStreams,
  setLayout,
  layout,
  setRightRotationHack,
  setLeftRotationHack,
}) => {
  const layouts = ["Brady Bunch", "Side by Side", "Left Featured"];

  const getSoloProps = () => {
    if (!videoStreams) {
      return <></>;
    }
    let uniquePeerIds = videoStreams.map((x) => x.peerId);
    uniquePeerIds = uniquePeerIds.filter(
      (value, index) => uniquePeerIds.indexOf(value) === index
    );

    return uniquePeerIds.map((peerId) => {
      const streams = videoStreams.filter((x) => x.peerId === peerId);
      //console.error(videoStreams);
      //console.error(peerId);
      if (streams.length === 1) {
        return (
          <li key={peerId} className="nav-item p-1 pe-2 m-0 " onClick={() =>
            setSolo((x) =>
              x === streams[0].assignedId ? null : streams[0].assignedId
            )
          }>
            <div className="d-flex justify-content-between">
              <a
                className="nav-link p-0"
                href="#"
              >
              {solo === streams[0].assignedId ? (
                  <img
                    src="/assets/icon-video-check.svg"
                    className="d-inline-block me-2"
                    width="16"
                    alt="check"
                  />
                ) : (
                  <img
                    src="/assets/transparent.png"
                    className="d-inline-block me-2"
                    width="16"
                    alt="blank"
                  />
                )}
                Only {streams[0]?.name}
              </a>
            </div>
          </li>
        );
      }
      return (
        <li key={peerId} className="nav-item p-1 pe-2 m-0">
          <div className="d-flex justify-content-between">
            <a href="#" className="nav-link p-0">
              {solo === streams[0].assignedId ? (
                <img
                  src="/assets/icon-video-check.svg"
                  className="d-inline-block me-2"
                  width="16"
                  alt="check"
                />
              ) : (
                <img
                  src="/assets/transparent.png"
                  className="d-inline-block me-2"
                  width="16"
                  alt="blank"
                />
              )}
              Only {streams[0]?.name}
            </a>
            <div>
              <svg
                className="svg-inline--fa fa-caret-right fa-w-6 ms-1"
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="caret-right"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 192 512"
                data-fa-i2svg=""
              >
                <path
                  fill="currentColor"
                  d="M0 384.662V127.338c0-17.818 21.543-26.741 34.142-14.142l128.662 128.662c7.81 7.81 7.81 20.474 0 28.284L34.142 398.804C21.543 411.404 0 402.48 0 384.662z"
                ></path>
              </svg>
            </div>
          </div>
          <ul className="submenu submenu-down navbar nav flex-column align-items-start p-0">
            {streams.map(({ label, assignedId }) => (
              <li key={assignedId} className="nav-item py-1 ps-3 m-0">
                <a
                  onClick={() =>
                    setSolo((x) => (x === assignedId ? null : assignedId))
                  }
                  className="nav-link p-0"
                  href="#"
                >
                  {label}
                </a>
              </li>
            ))}
          </ul>
        </li>
      );
    });
  };

  return (
    <>
      <button className="video-footer-button" id="layout">
        <img src="/assets/icon-video-layout.svg" alt="video" />
      </button>
      <div className="video-footer-label">Layout</div>
      <nav className="navbar video-footer-menu p-0">
        <ul className="navbar nav flex-column align-items-start p-0 video-dropdown-menu">
          {layouts.map((label, index) => (
            <li
              key={index}
              className="nav-item p-1 pe-2 m-0 "
              onClick={() => {
                setLayout(index);
                setSolo(null);
              }}
            >
              <div className="d-flex justify-content-between">
                <a className="nav-link p-0 text-white" href="#">
                  {layout === index && (
                    <img
                      src="/assets/icon-video-check.svg"
                      className="d-inline-block me-2"
                      width="16"
                      alt="check"
                    />
                  )}
                  All Users: {label}
                </a>
              </div>
            </li>
          ))}

          <li className="nav-item px-0 m-0 nav-label">
            <hr className="dropdown-divider border-secondary" />
          </li>

          {getSoloProps()}

          <li className="nav-item px-0 m-0 nav-label">
            <hr className="dropdown-divider border-secondary" />
          </li>
          <li className="nav-item p-1 pe-2 m-0 ">
            <div
              onClick={() => setLeftRotationHack((x) => !x)}
              className="d-flex justify-content-between"
            >
              <a className="nav-link p-0" href="#">
                <img
                  src="/assets/transparent.png"
                  className="d-inline-block me-2"
                  width="16"
                  alt="blank"
                />
                Rotate Users Right
              </a>
            </div>
          </li>
          <li className="nav-item p-1 pe-2 m-0 ">
            <div
              onClick={() => setRightRotationHack((x) => !x)}
              className="d-flex justify-content-between"
            >
              <a className="nav-link p-0" href="#">
                <img
                  src="/assets/transparent.png"
                  className="d-inline-block me-2"
                  width="16"
                  alt="blank"
                />
                Rotate Users Left
              </a>
            </div>
          </li>
          {/* <li className="nav-item p-1 pe-2 m-0 ">
            <div className="d-flex justify-content-between"><a className="nav-link p-0" href="#"><img src="/assets/transparent.png" className="d-inline-block me-2" width="16" />Shuffle Users</a></div>
          </li> */}
          <li className="video-dropdown-menu-caret p-0 m-0 text-center"></li>
        </ul>
      </nav>
    </>
  );
};
