import { CircularProgress, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import config from "../config";
import { Interact } from "./Interact";
import JamkazamVideo from "./JamkazamVideo";
import { DeviceContext } from "../DeviceContext";

function JamkazamVideoParent(props) {
  const { roomId } = useParams();
  const [interacted, setInteracted] = useState(false);
  const [restart, setRestart] = useState(false);
  const [producerState, setProducerState] = useState(false);
  const [initialVideoProps, setInitialVideoProps] = useState([]);
  const [constraintsSet, setConstraintsSet] = useState(false);
  const [forcedSfu, setForcedSfu] = useState(false);

  const [userDevices, setUserDevices] = useState({});
  const [userMediaReady, setUserMediaReady] = useState(false);

  useEffect(() => {
    if (restart) {
      window.setTimeout(() => setRestart(false), 2000);
    }
  }, [restart]);

  useEffect(() => {
    // navigator.permissions.query({ name: "camera" }).then(function (result) {
    //   if (result.state === "granted") {
    //     //permission has already been granted, no prompt is shown
    //   } else if (result.state === "prompt") {
    //     //there's no peristent permission registered, will be showing the prompt
    //   } else if (result.state === "denied") {
    //     //permission has been denied
    //   }
    // });

    navigator.mediaDevices.ondevicechange = function (event) {
      handleMediaDevices();
    };
    handleMediaDevices();
    return () => {
      navigator.mediaDevices.ondevicechange = null;
      setUserMediaReady(false);
    };
  }, []);

  const handleMediaDevices = async () => {
    // This is to trigger the permission prompt
    setUserMediaReady(false);
    await navigator.mediaDevices.getUserMedia({ audio: false, video: true })
      // .then(function (stream) {
      //   setUserMediaReady(true);
      // })
      // .catch(function (err) {
      //   console.log(err);
      //   setUserMediaReady(false);
      // });
    setUserMediaReady(true);

    navigator.mediaDevices.enumerateDevices().then(function (devices) {
      let videoDevices = [];
      let audioInputDevices = [];
      let audioOutputDevices = [];

      devices.map((device) => {
        if (device.kind.includes("videoinput")) {
          videoDevices.push(device);
        } else if (device.kind.includes("audioinput")) {
          audioInputDevices.push(device);
        } else if (device.kind.includes("audiooutput")) {
          audioOutputDevices.push(device);
        }
      });
      setUserDevices({
        userVideoDevices: videoDevices,
        userAudioInputDevices: audioInputDevices,
        userAudioOutputDevices: audioOutputDevices,
      });
    });
  };

  useEffect(() => {
    let cameraSet = false;
    const self = this;

    if(!userMediaReady) {
      return;
    }

    if (new URLSearchParams(window.location.search).get("canvas")) {
      setConstraintsSet(true);
      return;
    }
    
    navigator.mediaDevices.enumerateDevices().then(function (devices) {
      devices.map((device) => {
        if (device.kind.includes("videoinput") && !cameraSet) {
          cameraSet = true;
          setConstraintsSet(true);
          setInitialVideoProps([
            {
              constraints: {
                video: { deviceId: device.deviceId },
                type: "webcam",
              },
              label: device.label,
            },
          ]);
        }
      });
      if (!cameraSet) {
        setConstraintsSet(true);
        setInitialVideoProps([{ constraints: { video: {} }, type: "webcam" }]);
      }
    });
  }, [userMediaReady]);

  if (
    !interacted &&
    !new URLSearchParams(window.location.search).get("canvas")
  ) {
    return (
      <Interact
        onFinish={() => setInteracted(true)}
        userMediaReady={userMediaReady}
      />
    );
  }

  if (!constraintsSet) {
    return <> </>;
  }

  if (restart) {
    return (
      <div
        style={{
          color: "white",
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography variant="h6"> Trying to reconnect </Typography>
        <center>
          <CircularProgress color="secondary" />{" "}
        </center>
      </div>
    );
  }

  return (
    <DeviceContext.Provider value={userDevices}>
      <JamkazamVideo
        setForcedSfu={setForcedSfu}
        setRestart={setRestart}
        setInitialVideoProps={setInitialVideoProps}
        initialVideoProps={initialVideoProps}
        room={roomId}
        producerState={producerState}
        debug={new URLSearchParams(window.location.search).get("debug")}
        setProducerState={setProducerState}
        isP2p={config.options.p2p && !forcedSfu}
        admin={config.options.admin}
        audiooff={config.options.audiooff}
        token={config.options.token}
        canvas={config.options.canvas}
        simulcast={config.options.simulcast}
        session_creator={config.options.session_creator}
      />
    </DeviceContext.Provider>
  );
}

export default JamkazamVideoParent;
