import React, { useEffect } from 'react';
let canvas;
let streams = [];
const HEIGHT = 720;
const WIDTH = 1280;
const layouts = [
  [{
    width: WIDTH, height: HEIGHT, top: 0, left: 0, scaleX: 1, scaleY: 1
  }],
  [{
    width: WIDTH, height: HEIGHT, top: HEIGHT / 4, left: 0, scaleX: 0.5, scaleY: 0.5
  },
  {
    width: WIDTH, height: HEIGHT, top: HEIGHT / 4, left: WIDTH / 2, scaleX: 0.5, scaleY: 0.5
  }],
  [{
    width: WIDTH, height: HEIGHT, top: 0, left: 0, scaleX: 0.5, scaleY: 0.5
  },
  {
    width: WIDTH, height: HEIGHT, top: 0, left: WIDTH / 2, scaleX: 0.5, scaleY: 0.5
  },
  {
    width: WIDTH, height: HEIGHT, top: HEIGHT / 2, left: WIDTH / 4, scaleX: 0.5, scaleY: 0.5
  }],
  [{
    width: WIDTH, height: HEIGHT, top: 0, left: 0, scaleX: 0.5, scaleY: 0.5
  },
  {
    width: WIDTH, height: HEIGHT, top: 0, left: WIDTH / 2, scaleX: 0.5, scaleY: 0.5
  },
  {
    width: WIDTH, height: HEIGHT, top: HEIGHT / 2, left: 0, scaleX: 0.5, scaleY: 0.5
  },
  {
    width: WIDTH, height: HEIGHT, top: HEIGHT / 2, left: WIDTH / 2, scaleX: 0.5, scaleY: 0.5
  }]
]
export const FabricCanvasRenderer = ({ videoStreams }) => {
  const addLogo = () => {
    new window.fabric.Image.fromURL("/logo.png", function (logo) {
      logo.set({
        top: 30,
        left: WIDTH - 100,
        width: 2000,
        height: 400,
        scaleX: 0.1, scaleY: 0.1,
        originX: 'center',
        originY: 'center',
      })
      canvas.add(logo);
      canvas.moveTo(logo, 2);
      logo.moveTo(2)
    });
    canvas.setBackgroundColor("#8e44ad")
  }
  const init = () => {
    window.maincanvas = document.getElementById('maincanvas')
    canvas = new window.fabric.Canvas('maincanvas');
    // var video1El = document.getElementById('video1');
    addLogo()
    window.fabric.util.requestAnimFrame(function render() {
      canvas.renderAll();
      window.fabric.util.requestAnimFrame(render);
    });

  }

  const updateVideoLayout = () => {

    function handleResize({ assignedId, fabricRef, webcamEl }) {
      const noOfStreams = streams.length;
      const index = streams.findIndex(x => x.assignedId === assignedId);
      webcamEl.setAttribute("width", webcamEl.videoWidth);
      webcamEl.setAttribute("height", webcamEl.videoHeight);
      fabricRef.set({
        scaleX: layouts[noOfStreams - 1][index].scaleX * WIDTH / webcamEl.videoWidth,
        scaleY: layouts[noOfStreams - 1][index].scaleY * HEIGHT / webcamEl.videoHeight,
        width: webcamEl.videoWidth,
        height: webcamEl.videoHeight
      })
    }
    const noOfStreams = streams.length;

    streams.map(({ stream, fabricRef, assignedId, webcamEl }, index) => {
      if (!fabricRef) {
        var webcamEl = document.createElement('video');
        webcamEl.setAttribute("width", 1080)
        webcamEl.setAttribute("height", 720)
        var webcam = new window.fabric.Image(webcamEl, {
          ...layouts[noOfStreams - 1][index],
          objectCaching: false,
        });
        webcamEl.addEventListener("resize", () => handleResize({ assignedId, fabricRef: webcam, webcamEl }))
        webcamEl.srcObject = stream;
        webcamEl.setAttribute("playsinline", true);
        webcamEl.setAttribute("autoplay", true);
        webcamEl.play();
        webcam.getElement().play();

        streams[index].fabricRef = webcam;
        streams[index].webcamEl = webcamEl;
        canvas.add(webcam);
        webcam.moveTo(0)
      } else {
        fabricRef.set({
          ...layouts[noOfStreams - 1][index],
          scaleX: layouts[noOfStreams - 1][index].scaleX * WIDTH / webcamEl.videoWidth,
          scaleY: layouts[noOfStreams - 1][index].scaleY * HEIGHT / webcamEl.videoHeight,
          width: webcamEl.videoWidth,
          height: webcamEl.videoHeight
        })
      }
    })
  }

  useEffect(() => {
    if (videoStreams.length === 0) {
      return;
    }
    streams.map(({ assignedId, fabricRef }, index) => {
      if (!videoStreams.find(x => x.assignedId === assignedId)) {
        //this stream was deleted
        canvas.remove(fabricRef);
        streams.splice(index, 1);
      }
    })
    videoStreams.map(({ assignedId, stream }) => {
      if (!streams.find(x => x.assignedId === assignedId)) {
        streams.push({
          stream,
          assignedId
        })
      }
    })
    streams.sort((a, b) => videoStreams.findIndex(x => x.assignedId === a.assignedId) < videoStreams.findIndex(x => x.assignedId === b.assignedId) ? -1 : 1);
    console.error(streams);
    updateVideoLayout()
    // var webcamEl = document.createElement('video');
    // webcamEl.setAttribute("width", 1080)
    // webcamEl.setAttribute("height", 720)
    // var webcam = new window.fabric.Image(webcamEl, {
    //   width: 1080,
    //   height: 720,
    //   left: 50,
    //   top: 70,
    //   scaleX: 0.5,
    //   scaleY: 0.5,
    //   objectCaching: false,
    // });
    // webcamEl.addEventListener("resize", () => {
    //   webcamEl.setAttribute("width", webcamEl.videoWidth);
    //   webcamEl.setAttribute("height", webcamEl.videoHeight);
    //   webcam.set({
    //     scaleX: 0.5 * 1280/webcamEl.videoWidth,
    //     scaleY: 0.5 * 720 /webcamEl.videoHeight
    //   })
    // })
    // webcamEl.srcObject = videoStreams[0].stream;
    // webcamEl.setAttribute("playsinline", true);
    // webcamEl.setAttribute("autoplay", true);
    // webcamEl.play();
    // webcam.moveTo(0)
    // webcam.getElement().play();

    // // document.body.appendChild(webcamEl);
    // canvas.add(webcam);
  }, [videoStreams])

  useEffect(() => {
    init();
  }, [])

  return (
    <div>
      <canvas id="maincanvas" width="1280" height="720"></canvas>
    </div>
  )
}
