import React, { useEffect, useRef, useState } from "react";
import config from "../../config";
import { NONE, NAME_AND_LOCATION, FIRST_NAME } from "../constants";

export const IndividualVideo = (props) => {
  const ASPECT_RATIO = config.aspectRatios;
  const videoRef = useRef(null);
  const [marginTop, setMarginTop] = useState(56.25);
  const localVideoProps = props.localVideoStreams.find(
    (x) => x.assignedId === props.assignedId
  );

  const handleChangeResolution = ({ assignedId, resolution }) => {
    window.adapter.changeResolution({ assignedId, resolution });
  };

  useEffect(() => {
    if (!videoRef || !videoRef.current) {
      return;
    }
    videoRef.current.srcObject = props.stream;

    videoRef.current.play();
    function listener() {
      if (videoRef.current) {
        const { videoWidth, videoHeight } = videoRef.current;
        setMarginTop(
          56.25 +
            (100 * (videoHeight - (videoWidth * 1) / 1.77)) / (2 * videoHeight)
        );
      }
    }
    videoRef.current.addEventListener("resize", listener);
    return () => {
      if (videoRef.current)
        videoRef.current.removeEventListener("resize", listener);
    };
  }, [videoRef, props.stream]);

  const getFlippedClass = () => {
    let horizontalFlip = props.horizontalFlip;
    let className = "";
    if (props.peerId === "local" && props.flipOutgoingOrientation) {
      horizontalFlip = !horizontalFlip;
    }

    if (horizontalFlip && props.verticalFlip) {
      className += " flipHorizontalAndVertical ";
    }
    if (horizontalFlip) {
      className += " flipHorizontal ";
    }
    if (props.verticalFlip) {
      className += " flipVertical ";
    }

    if (props.filters.grayscale) {
      className += " grayscale ";
    }

    if (props.filters.brightness) {
      if (props.filters.grayscale) {
        className += ` bright-${props.filters.brightness}grayscale `;
      } else {
        className += ` bright-${props.filters.brightness} `;
      }
    }

    return className;
  };

  const stopVideo = (assignedId) => {
    if (!assignedId) {
      return;
    }
    props.localVideoStreams.map((vs) => {
      if (vs.assignedId !== assignedId) {
        return;
      }
      window.adapter.destroyMediaProducer({
        assignedId: vs.assignedId,
      });
      vs.stream.getTracks().forEach(function (track) {
        track.stop();
      });
    });
    // setVideoAssignedId(vs => vs.filter(x => x.assignedId !== assignedId)[0]?.assignedId || null);
    props.setLocalVideoStreams((vs) =>
      vs.filter((x) => x.assignedId !== assignedId)
    );
  };

  const firstName = props.name?.split(" ")[0];
  const lastName = props.name?.split(" ")[1];
  return (
    <div style={{ color: "white" }}>
      <div className="video-item">
        <div
          style={{
            height: 0,
            paddingTop: "56.25%",
            overflow: "hidden",
            background: "black",
            border: props.isMuted ? "1px solid white" : "",
          }}
        >
          <div
            style={{
              marginTop: "-" + marginTop + "%",
              visibility: props.isMuted ? "hidden" : "visible",
            }}
          >
            <video
              className={getFlippedClass()}
              style={{
                zIndex: -1,
                width: "100%",
                border:
                  props.pinned === props.assignedId ? "1px solid white" : "",
              }}
              ref={videoRef}
              autoPlay={true}
              playsInline={true}
            />
          </div>
          <div
            style={{
              marginTop: "-" + marginTop * 0.62 + "%",
              width: "100%",
              visibility: !props.isMuted ? "hidden" : "visible",
            }}
          >
            <center>
              <div
                style={{
                  border:
                    props.pinned === props.assignedId ? "1px solid white" : "",
                }}
              >
                <img
                  width="20%"
                  src={`https://ui-avatars.com/api/?name=${props.name}&background=2c3e50&color=ecf0f1&rounded=true`}
                />
              </div>
            </center>
          </div>
        </div>
        <div className="video-label">
          {props.labelMode !== NONE && firstName}{" "}
          {props.labelMode !== NONE &&
            props.labelMode !== FIRST_NAME &&
            lastName}
          {/* <div className="video-label-location">
            {props.labelMode === NAME_AND_LOCATION && "Austin, Texas US"}
          </div> */}
        </div>
        <div className="video-menu" id="videoMenu1Control">
          <nav
            className="navbar navbar-dark bg-dark video-navbar"
            style={{
              height: "24px",
              width: "24px",
              position: "absolute",
              top: "0px",
              right: "4px",
            }}
          >
            <button
              className="navbar-toggler video-navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#videoMenu1"
              area-controls="videoMenu1"
              aria-expanded="false"
              aria-label="Toggle navigation"
            ></button>
          </nav>
          <nav className="navbar collapse navbar-dark bg-dark" id="videoMenu1">
            <ul className="navbar-nav">
              <li className="text-white nav-item nav-label pt-2">
                {(props.label || localVideoProps?.label)?.substr(0, 15)}:
              </li>
              {localVideoProps && (
                <>
                  <li className="nav-item">
                    <a
                      onClick={() => stopVideo(props.assignedId)}
                      className="nav-link"
                      href="#"
                    >
                      Turn Camera Off
                    </a>
                  </li>
                  <li className="nav-item nav-label">Resolution:</li>
                  {Object.keys(ASPECT_RATIO).map((index) => (
                    <li key={index} className="nav-item py-1 ps-3 m-0">
                      <div
                        className="d-flex justify-content-between"
                        onClick={() => {
                          handleChangeResolution({
                            resolution: index,
                            assignedId: props.assignedId,
                          });
                        }}
                      >
                        <a className="nav-link p-0 text-white" href="#">
                          {localVideoProps.resolution == index && (
                            <img
                              src="/assets/icon-video-check.svg"
                              className="d-inline-block me-2"
                              width="16"
                            />
                          )}
                          {ASPECT_RATIO[index].width}x
                          {ASPECT_RATIO[index].height}
                        </a>
                      </div>
                    </li>
                  ))}
                </>
              )}
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="#"
                  onClick={() =>
                    props.flipLocal({
                      direction: "horizontal",
                      assignedId: props.assignedId,
                    })
                  }
                >
                  {props.horizontalFlip && (
                    <img
                      src={"/assets/icon-video-check.svg"}
                      className="d-inline-block me-2"
                      width="16"
                    />
                  )}
                  Flip Horizontal
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="#"
                  onClick={() =>
                    props.flipLocal({
                      direction: "vertical",
                      assignedId: props.assignedId,
                    })
                  }
                >
                  {props.verticalFlip && (
                    <img
                      src={"/assets/icon-video-check.svg"}
                      className="d-inline-block me-2"
                      width="16"
                    />
                  )}
                  Flip Vertical
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
};
