import React, { useContext } from 'react';
import config from '../../config';
import { DeviceContext } from '../../DeviceContext';


export const CameraSelector = ({ flipLocal, setVideoStreams, flipOutgoingOrientation, localVideoStreams, setVideoAssignedId, setLocalVideoStreams }) => {
  const ASPECT_RATIO = config.aspectRatios;
  
  const { userVideoDevices }= useContext(DeviceContext)

  const handleChangeResolution = ({ assignedId, resolution }) => {
    window.adapter.changeResolution({ assignedId, resolution })
  }

  const startCam = ({ deviceId, resolution , label}) => {
    window.adapter.createVideoProducer({ video: { deviceId: deviceId } }, null, resolution, label)
  }

  const stopVideo = (assignedId) => {
    if (!assignedId) {
      return;
    }
    localVideoStreams.map(vs => {
      if (vs.assignedId !== assignedId) {
        return;
      }
      window.adapter.destroyMediaProducer({
        assignedId: vs.assignedId,
      })
      vs.stream.getTracks().forEach(function (track) {
        track.stop();
      });
    });
    // setVideoAssignedId(vs => vs.filter(x => x.assignedId !== assignedId)[0]?.assignedId || null);
    setLocalVideoStreams(vs => vs.filter(x => x.assignedId !== assignedId));
  }

  const flipHorizontal = (assignedId) => {
    let oldValue;
    setVideoStreams(streams => streams.map(vs => {
      if (vs.assignedId !== assignedId) {
        return vs;
      }
      oldValue = vs.horizontalFlip;
      return {
        ...vs,
        horizontalFlip: !vs.horizontalFlip
      }
    }))
    localStorage.setItem("jk-horizontal-flip", !oldValue)
    window.adapter.emit({
      type: "flip",
      direction: "horizontal",
      value: flipOutgoingOrientation ^ !oldValue,
      assignedId
    });
  }

  const flipVertical = (assignedId) => {
    let oldValue;
    setVideoStreams(streams => streams.map(vs => {
      if (vs.assignedId !== assignedId) {
        return vs;
      }
      oldValue = vs.verticalFlip
      return {
        ...vs,
        verticalFlip: !vs.verticalFlip
      }
    }))
    window.adapter.emit({
      type: "flip",
      direction: "vertical",
      value: flipOutgoingOrientation ^ !oldValue,
      assignedId
    })
  }

  return (
    <>
      <button className="video-footer-button" id="myCameras">
        <img src="/assets/icon-video-mycameras.svg" />
        <span className="video-footer-badge badge bg-primary">{userVideoDevices.length}</span>
      </button>
      <div className="video-footer-label">My Cameras</div>
      <nav className="navbar video-footer-menu p-0">
        <ul className="navbar nav flex-column align-items-start p-0 video-dropdown-menu">
          {userVideoDevices.map((camera, index) => {
            const camInUse = localVideoStreams.find(x => x.deviceId === camera.deviceId);
            const camAssignedId = camInUse?.assignedId;
            return <li key={`camera${index}`} className="nav-item p-1 pe-2 m-0 ">
              <div className="d-flex justify-content-between">
                <a className="nav-link p-0 text-white" href="#" alt="">
                  {camInUse && <img src="/assets/icon-video-check.svg" className="d-inline-block me-2" width="16" alt="" />}
                  {camera.label || "Camera " + (index + 1)}
                </a>
                <div><svg className="svg-inline--fa fa-caret-right fa-w-6 ms-1" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="caret-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512" data-fa-i2svg=""><path fill="currentColor" d="M0 384.662V127.338c0-17.818 21.543-26.741 34.142-14.142l128.662 128.662c7.81 7.81 7.81 20.474 0 28.284L34.142 398.804C21.543 411.404 0 402.48 0 384.662z"></path></svg></div>
              </div>
              <ul className="submenu navbar nav flex-column align-items-start p-0">
                <li className="nav-item py-1 ps-3 m-0">
                  <a onClick={() => stopVideo(localVideoStreams.find(x => x.deviceId === camera.deviceId)?.assignedId)}
                    className="nav-link p-0 text-white"
                    href="#"
                  >
                    Turn Camera Off
                </a>
                </li>
                <li className="nav-item py-1 ps-3 m-0 nav-label">Resolution:</li>
                {Object.keys(ASPECT_RATIO).map((index) => <li key={`aspect-raio${index}`} className="nav-item py-1 ps-3 m-0">
                  <div className="d-flex justify-content-between" onClick={() => {
                    if (camInUse) {
                      handleChangeResolution({ resolution: index, assignedId: camAssignedId })
                    } else {
                      startCam({ resolution: index, deviceId: camera.deviceId, label: camera.label })
                    }
                  }}>
                    <a className="nav-link p-0 text-white" href="#" alt="">
                      {localVideoStreams.find(x => x.resolution == index && x.deviceId === camera.deviceId) && <img src="/assets/icon-video-check.svg" className="d-inline-block me-2" width="16" alt="" />}
                      {ASPECT_RATIO[index].width}x{ASPECT_RATIO[index].height}
                    </a>
                  </div>
                </li>)}
                {camInUse && <>
                  <li onClick={() => flipLocal({assignedId: camAssignedId, direction: "horizontal"})} className="nav-item py-1 ps-3 m-0">
                    <a className="nav-link p-0" href="#">
                    {camInUse.horizontalFlip && <img src={"/assets/icon-video-check.svg"} className="d-inline-block me-2" width="16" />}Flip Horizontal</a></li>
                  <li onClick={() => flipLocal({assignedId: camAssignedId, direction: "vertical"})} className="nav-item py-1 ps-3 m-0">
                    <a className="nav-link p-0" href="#">
                    {camInUse.verticalFlip && <img src={"/assets/icon-video-check.svg"} className="d-inline-block me-2" width="16" />}Flip Vertical</a></li>
                </>}
              </ul>
            </li>
          }
          )}

          <li className="video-dropdown-menu-caret p-0 m-0 text-center"></li>
        </ul>
      </nav>
    </>
  )
}
