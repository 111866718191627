import { Grid } from '@material-ui/core';
import React from 'react';
import { IndividualVideo } from './IndividualVideo';

export const SoloLayout = (props) => {
  const pinnedVideoIndex = props.videoStreams.findIndex((videoStream) => videoStream.assignedId === props.solo);
  const pinnedVideo = props.videoStreams[pinnedVideoIndex]
  return (
    <div>
      <Grid container>

      <Grid item sm={0} md={0} lg={1}></Grid>
      <Grid item sm={12} md={12} lg={10}>
          <IndividualVideo {...props} {...pinnedVideo} />
        </Grid>
      </Grid>
    </div>
  )
}
