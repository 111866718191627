import { Grid } from '@material-ui/core';
import React, { useContext } from 'react';
import { AppContext } from '../../AppContext';
import { IndividualVideo } from './IndividualVideo';

export const Layout2 = (props) => {
  const { isMobile } = useContext(AppContext);

  if (props.layout === 1 || isMobile) {
    return (
      <div>
        <Grid container>
          {props.videoStreams.map(vs =>
            <Grid key={vs.assignedId} item xs={12} md={6} lg={6}>
              <IndividualVideo {...props}  {...vs} />
            </Grid>
          )}
        </Grid>
      </div>
    )
  }
  return <div>
    <Grid container>
      <Grid item sm={0} md={1} lg={1}>
      </Grid>
      <Grid item sm={12} md={10} lg={10}>
        <IndividualVideo {...props} key={props.videoStreams[0].assignedId} {...props.videoStreams[0]} />
        <div style={{ display: "flex"}}>
          <div style={{
            position: "absolute", width: "50%", maxWidth: "350px", zIndex: 10, marginTop: -210
          }}>
            <IndividualVideo {...props} key={props.videoStreams[1].assignedId} {...props.videoStreams[1]} />
          </div>
        </div>
      </Grid>
    </Grid>
  </div>
}
