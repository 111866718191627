import { Grid, Typography } from '@material-ui/core'
import React, { useContext } from 'react'
import { IndividualVideo } from './IndividualVideo'
import { AppContext } from '../../AppContext';

export const ScreenshareLayout = (props) => {
  const screenshareVideoStream = props.videoStreams.find(x => x.assignedId === props.someoneIsSharing.assignedId);
  const nonScreenSharedVideos =  props.videoStreams.filter(x => x.assignedId !== props.someoneIsSharing.assignedId);
  const {isMobile} = useContext(AppContext);

  const getStyles = () => {
    if(isMobile) {
      return {width: "217px", height: "130.5px"}
    }
    return {width: "290px", height: "174px"}
  }
  return (
    <div>
      <div style={{display: "flex", justifyContent: "center", justifyItems: "center"}}>
          {nonScreenSharedVideos.map(videoStream => <div style={getStyles()}>
            <IndividualVideo {...props} key={videoStream.assignedId} {...videoStream} />  
          </div>)}
      </div>
      {screenshareVideoStream && screenshareVideoStream.assignedId && <Grid container>
        <Grid item sm={12} md={1} lg={2}>
        </Grid>
        <Grid item sm={12} md={10} lg={8}>
          <IndividualVideo {...props} key={screenshareVideoStream.assignedId} {...screenshareVideoStream} />
          <center>
            <span style={{ zIndex: 100, color: "white"}}>
              <Typography variant="subtitle1" style={{background: "#2c3e50"}}>
                <br/>{screenshareVideoStream.name} is sharing
              </Typography>
            </span>
          </center>
        </Grid>
      </Grid>}
    </div>
  )
}
