import { v4 as uuid } from 'uuid';
let peers = [];
let trackToStreamMapping = {}; //used for finding the right sender in replaceTrack

const ongoingOffers = {

};

const offerIncoming = {

}

export default class P2pAdapter {
  constructor(socket, callback, producerParams, aspectRatios, room) {
    this.room = room;
    this.socket = socket;
    this.callback = callback;
    this.socket.on("p2pEvent", this.p2pEventHandler.bind(this));
    this.producerParams = producerParams;
    this.aspectRatios = aspectRatios;
    this.videoStreams = [];
    this.name = "";
    this.desktopMediaIds = []
  }

  p2pEventHandler(payload) {
    switch (payload.type) {
      case "addPc":
        this.addPc(payload);
        break;
      case "offerCreated":
        this.offerCreated(payload);
        break;
      case "answerCreated":
        this.answerCreated(payload);
        break;
      case "triggerRenogitation":
        this.triggerRenogitation(payload);
        break;
      case "canINegotiate":
        this.canINegotiate(payload)
        break;
      case "youCanNegotiate":
        this.youCanNegotiate(payload)
    }
  }

  switchToSfu() {
    this.callback({
      type: "forceSfu"
    });
  }

  answerCreated({data}) {
    delete ongoingOffers[data.id]
    const answer = data.answer;
    const relevantPc = peers.find(peer => peer.id === data.id)?.pc;
    if(!relevantPc) {
      return;
    }
    relevantPc.setRemoteDescription(answer);
  }

  async offerCreated({data}) {
    const offer = data.offer;
    const relevantPc = peers.find(peer => peer.id === data.id)?.pc;
    if(!relevantPc) {
      console.error("PC NOT FOUND FOR PEER" + data.id)
      return;
    }
    await relevantPc.setRemoteDescription(offer);
    offerIncoming[data.id] = false;
    const answer = await relevantPc.createAnswer();
    await relevantPc.setLocalDescription(answer);
    this.socket.emit("p2pEvent", {
      type: "answerCreated",
      to: data.id,
      data: {
        answer: relevantPc.localDescription,
        id: this.id
      }
    })
  }

  async negoatiateWhenReady({data}) {
    if (offerIncoming[data.id]) {
      // window.setTimeout(() => this.negoatiateWhenReady({data}), 500)
      return;
    }
    ongoingOffers[data.id] = true;
    this.youCanNegotiate({data})
  }

  async youCanNegotiate({data}) {
    const relevantPc = peers.find(peer => peer.id === data.id)?.pc;
    const offer = await relevantPc.createOffer();
    await relevantPc.setLocalDescription(offer);

    this.socket.emit("p2pEvent", {
      type: "offerCreated",
      to: data.id,
      data: {
        offer: relevantPc.localDescription,
        id: this.id
      }
    })
  }

  canINegotiate({data}) {
    if (ongoingOffers[data.id]) {
      return;
    }
    offerIncoming[data.id] = true;
    this.socket.emit("p2pEvent", {
      type: "youCanNegotiate",
      to: data.id,
      data: {
        id: this.id
      }
    })
  }
  
  addPc({data}) {
    const pc = new RTCPeerConnection({iceServers: this.iceServers});
    pc.onicecandidate = (e) => {
      if(e.candidate) {
        this.socket.emit("p2pEvent", {
          type: "addIceCandidate",
          to: data.id,
          data: {
            id: this.id,
            candidate: e.candidate
          }
        })
      }
    }
    pc.onnegotiationneeded = async () => {
      this.socket.emit("p2pEvent", {
        type: "canINegotiate",
        to: data.id,
        data: {
          id: this.id,
        }
      })
    }
    peers.push({
      id: data.id,
      pc,
      name: data.name
    })

    pc.ontrack = ({track, streams}) =>  this.receivedTrack({track, peerId: data.id, streams, name: peers.find(peer => peer.id === data.id)?.name});
    if (this.audioStream) {
      pc.addTrack(this.audioStream.getAudioTracks()[0])
    }
    if (this.videoStreams) {
      this.videoStreams.map(({stream, track}) => {
        if (track) {  
          pc.addTrack(track, stream)
        } else {
          pc.addTrack(stream.getVideoTracks()[0], stream)
        }
      })
    }
  }

  sendSwitchToSfu() {
    this.socket.emit("metaEvent", {
      type: "forceSfu"
    })
  }

  destroy() {
    peers.forEach(x => x.pc?.close());
    peers = [];
    trackToStreamMapping = [];
  }

  receivedTrack({track, peerId, streams, name}) {
    this.callback({
      type: "consumemedia", data: {
        track: track, peerId,
        assignedId: streams[0]?.id,
        name,
        isDesktop: this.desktopMediaIds.includes(streams[0]?.id)
      }
    })
  }

  async identityReceived (data, iceServers) {
    this.id = data.id;
    this.iceServers = iceServers;
    this.name = data.name
    this.desktopMediaIds = data.desktopMediaIds || [];
    data.peers.map(({peerId, name}) => {
      const pc = new RTCPeerConnection({iceServers: this.iceServers});
      this.socket.emit("p2pEvent", {
        type: "addPc",
        to: peerId,
        data: {
          id: this.id,
          name: this.name
        }
      })
      pc.onicecandidate = (e) => {
        if(e.candidate) {
          this.socket.emit("p2pEvent", {
            type: "addIceCandidate",
            to: peerId,
            data: {
              candidate: e.candidate,
              id: this.id
            }
          })
        }
      }
      pc.onnegotiationneeded = async () => {
        try {
          this.negoatiateWhenReady({data: {id: peerId}})
        } catch(e) {
          console.error(e);
        } finally {
        }
      }
      pc.ontrack = ({track, streams}) =>  this.receivedTrack({track, peerId: peerId, streams, name: peers.find(peer => peer.id === peerId)?.name});
      peers.push({
        id: peerId,
        pc,
        name
      })
    })

    if (this.producerParams.audio) {
      this.createAudioProducer({audio: false})
    }

    this.callback({
      type: "p2pInitialized"
    })

  }
  createAudioProducer(constraints) {
    this.audioTransmitting = true;
    navigator.mediaDevices.getUserMedia(constraints)
      .then(async stream => {
        this.audioStream = stream;
        this.callback({
          type: "audiocreated",
          data: {
            stream,
            assignedId: stream.id
          }
        })
        let track = stream.getAudioTracks()[0];
        peers.map(peer => {
          peer.pc.addTrack(track)
        })
        trackToStreamMapping[track.id] = stream.id;
      })
      .catch(e => console.log(e));
  }
  updateRtpParams(assignedId, params) {
    peers.map(peer => {
      peer.pc.getSenders().map(async sender => {
        if(assignedId === trackToStreamMapping[sender.track.id]) {
          const parameters = await sender.getParameters();
          console.error(parameters);
          parameters.encodings[0] =  {
            ...parameters.encodings[0],
            ...params
          }
          sender.setParameters(parameters)
        }
      })
    })
  }
  replaceTrack({assignedId, track, constraints, stream}) {
    peers.map(peer => {
      peer.pc.getSenders().map(sender => {
        if(assignedId === trackToStreamMapping[sender.track.id]) {
          sender.replaceTrack(track);
          trackToStreamMapping[track.id] = assignedId;
        }
      })
    })
    this.videoStreams = this.videoStreams.map(x => {
      if (x.assignedId === assignedId) {
        return {
          track: track,
          stream,
          ...x
        }
      }
      return x;
    })
  }
  addVideoStream(stream, isDesktop) {
    this.videoStreams.push({
      stream,
      assignedId: stream.id
    });
    const track = stream.getVideoTracks()[0];
    trackToStreamMapping[track.id] = stream.id;
    if (isDesktop) {
      this.socket.emit("metaEvent", {
        type: "addedDesktopProducer",
        mediaId: stream.id
      })
      window.setTimeout(() => {
        peers.map(peer => {
          peer.pc.addTrack(track, stream)
        })
      }, 500) // add timeout to make sure the mediaId reaches everyone
    } else {
      peers.map(peer => {
        peer.pc.addTrack(track, stream)
      })
    }
  }
}