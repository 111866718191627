import React, { useEffect, useState } from 'react';

export const AdminControls = ({admin, sendControlUpdateToNativeApp}) => {
  const [data, setData] = useState({});
  const [data2, setData2] = useState({});
  const [audioBits, setAudioBits] = useState(0);
  const [videoBits, setVideoBits] = useState(0);
  const [audioBits2, setAudioBits2] = useState(0);
  const [videoBits2, setVideoBits2] = useState(0);
  const [ans, setAnswer] = useState({});

  useEffect(() => {
    const interval1 = window.setInterval(async () => {
      if (window.adapter) {
        const data = await window.adapter.getIncomingBandwidth()
        setData(data);
        setVideoBits(videoBits => {
          setAnswer(a => ({
            ...a,
            incomingVideo: (data.videoBytes - videoBits) * 8 / 1000 || 0
          }))
          return data.videoBytes;
        })
        setAudioBits(audioBits => {
          setAnswer(a => ({
            ...a,
            incomingAudio: (data.audioBytes - audioBits) * 8 / 1000 || 0
          }))
          return data.audioBytes;
        })
      }
    }, 1000)
    const interval2 = window.setInterval(async () => {
      if (window.adapter) {
        const data = await window.adapter.getOutgoingBandwidth()
        setData2(data);
        setVideoBits2(videoBits => {
          setAnswer(a => ({
            ...a,
            outgoingVideo: (data.videoBytes - videoBits) * 8 / 1000 || 0
          }))
          return data.videoBytes;
        })
        setAudioBits2(audioBits => {
          setAnswer(a => ({
            ...a,
            outgoingAudio: (data.audioBytes - audioBits) * 8 / 1000 || 0
          }))
          return data.audioBytes;
        })
      }
    }, 1000)
    const interval3 = window.setInterval(() => {
      setAnswer(a => {
        sendControlUpdateToNativeApp("stats", a);
        return a
      })
    }, 1000)
    return () => {
      window.clearInterval(interval1);
      window.clearInterval(interval2);
      window.clearInterval(interval3);
    }
  }, [])

  return (
    <>
    {admin && <div style={{ color: "white", position: "absolute", top: 0, right: 0 }}>
      Incoming video - {(ans.incomingVideo)} Kb/s<br />
      Incoming audio - {(ans.incomingAudio)} Kb/s<br />
      Outgoing audio - {(ans.outgoingAudio)} Kb/s<br />
      Outgoing video - {(ans.outgoingVideo)} Kb/s<br />
    </div>}
    </>
  )
}
