
import { makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';


const useStyles = makeStyles((theme) => ({
  img: {
    width: 25
  },
  btn: {
    background: "#ecf0f1",
    width: 30,
    height: 30,
    marginRight: theme.spacing(1),
    '&:hover': {
      backgroundColor: "white"
    }
  }
}))

export const MuteIcon = ({ setAudioState, peerId, audioState, setLocalAudioStreams, setVideoDevices, audioAssignedId }) => {
  const classes = useStyles();
  const [mics, setMics] = useState([]);
  const [sinks, setSinks] = useState([]);

  const handleSinkChange = ({ deviceId }) => {
    let audios = document.querySelectorAll("audio");
    audios.forEach(audio => audio.setSinkId(deviceId))
  }

  const handleMicChange = ({ deviceId }) => {
    let constraints = { deviceId: { exact: deviceId } };
    if (!deviceId || deviceId === "") {
      constraints = true;
    }
    navigator.mediaDevices.getUserMedia({ audio: false })
      .then(stream => {
        const track = stream.getAudioTracks()[0];
        window.adapter.replaceTrack({ track, assignedId: audioAssignedId, isAudio: true });
      })
  }

  useEffect(() => {
    refreshDevices()
    navigator.mediaDevices.ondevicechange = function (event) {
      refreshDevices();
    };
  }, [])

  const refreshDevices = () => {
    let audioDevices = [];
    let videoDevices = [];
    let speakerDevices = [];
    let videoIndex = 1, micIndex = 1, speakerIndex = 1;
    navigator.mediaDevices
      .enumerateDevices()
      .then(function (devices) {
        devices.map(device => {
          if (device.kind.includes("videoinput")) {
            if (!device.label) {
              device.label = `Cam ${videoIndex++}`
            }
            videoDevices.push(device);
            setVideoDevices(videoDevices)
          } else if (device.kind.includes("audioinput")) {
            if (!device.label) {
              device.label = `Mic ${micIndex++}`
            }
            audioDevices.push(device);
            setMics(audioDevices)
          } else if (device.kind.includes("audiooutput")) {
            if (!device.label) {
              device.label = `Speaker ${speakerIndex++}`
            }
            speakerDevices.push(device);
            setSinks(speakerDevices)
          }
        });
      })
      .catch(function (err) {
        console.log(err.name + ": " + err.message);
      });
    //setVideoDevices(videoDevices)
    //setMics(audioDevices);
    //setSinks(speakerDevices);
  }

  const toggleMuteIcon = (peerId, newAudioMuted) => {
    setAudioState(audioState => ({
      ...audioState,
      [peerId]: {
        ...audioState[peerId],
        audioMuted: newAudioMuted
      }
    }))
  }
  const toggleMute = (peerId, newAudioMuted) => {
    setLocalAudioStreams(localAudioStreams => {
      if (peerId === "local") {
        if (!localAudioStreams[0]) {
          if (window.adapter.audioTransmitting) {
            console.warn("Creating audio element!")
            return localAudioStreams;
          }
          if (!newAudioMuted) {
            window.adapter.createAudioProducer({ audio: false });
            window.adapter.muteToggled(false);
            toggleMuteIcon(peerId, newAudioMuted);
          } else {
            console.log("local audio stream not found, have you provided access?");
          }
          return localAudioStreams;
        }
        if (newAudioMuted) {
          window.adapter.pauseMediaProducer({
            assignedId: localAudioStreams[0].assignedId,
          })
          window.adapter.muteToggled(newAudioMuted);
        } else {
          window.adapter.resumeMediaProducer({ assignedId: localAudioStreams[0].assignedId });
          window.adapter.muteToggled(newAudioMuted);
        }
        toggleMuteIcon(peerId, newAudioMuted);
      } else {
        // user is a remote peer
        window.adapter.muteUser(peerId);
      }
      return localAudioStreams;
    })
  }

  return (
    <>
      <button onClick={() => {
        if (peerId === "local") {
          toggleMute(peerId, !audioState["local"]?.audioMuted);
        } else {
          window.adapter.muteUser(peerId);
        }
      }} class="video-footer-button" id="chatAudio">
        {!audioState[peerId]?.audioMuted ? <img src="/assets/icon-video-chat-audio.svg" /> : <img src="/assets/icon-video-chataudio-mute.svg" />}
      </button>
      <div class="video-footer-label">Start Chat Audio</div>
      <nav class="navbar video-footer-menu p-0 chat-audio">
        <ul class="navbar nav flex-column align-items-start p-0 video-dropdown-menu">
          <li class="nav-item p-1 pe-2 m-0">
            <div class="d-flex justify-content-start">
              <div><svg class="svg-inline--fa fa-caret-left fa-w-6 me-2" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="caret-left" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512" data-fa-i2svg=""><path fill="currentColor" d="M192 127.338v257.324c0 17.818-21.543 26.741-34.142 14.142L29.196 270.142c-7.81-7.81-7.81-20.474 0-28.284l128.662-128.662c12.599-12.6 34.142-3.676 34.142 14.142z"></path></svg></div>
              <a class="nav-link p-0" href="#">Select Microphone</a>
            </div>
            <ul class="submenu submenu-left navbar nav flex-column align-items-start p-0">
              {mics.map((mic, index) => <li class="nav-item py-1 ps-3 m-0">
                <div onClick={() => handleMicChange({ deviceId: mic.deviceId })} class="d-flex justify-content-between"><a class="nav-link p-0" href="#">
                  {/* <img src="/assets/transparent.png" class="d-inline-block me-2" width="16" /> */}
                  {mic.label || `Mic ${index + 1}`}
                </a></div>
              </li>)}
            </ul>
          </li>
          <li class="nav-item p-1 pe-2 m-0">
            <div class="d-flex justify-content-start">
              <div><svg class="svg-inline--fa fa-caret-left fa-w-6 me-2" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="caret-left" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512" data-fa-i2svg=""><path fill="currentColor" d="M192 127.338v257.324c0 17.818-21.543 26.741-34.142 14.142L29.196 270.142c-7.81-7.81-7.81-20.474 0-28.284l128.662-128.662c12.599-12.6 34.142-3.676 34.142 14.142z"></path></svg></div>
              <a class="nav-link p-0" href="#">Select Speakers</a>
            </div>
            <ul class="submenu submenu-left navbar nav flex-column align-items-start p-0">
              {sinks.map((speaker, index) => <li class="nav-item py-1 ps-3 m-0">
                <div onClick={() => handleSinkChange({ deviceId: speaker.deviceId })} class="d-flex justify-content-between">
                  <a class="nav-link p-0" href="#">
                    {/* <img src="/assets/transparent.png" class="d-inline-block me-2" width="16" /> */}
                    {speaker.label || `Speaker ${index + 1}`}
                  </a></div>
              </li>)}
            </ul>
          </li>
          <li class="video-dropdown-menu-caret p-0 m-0 text-center"></li>
        </ul>
      </nav>
    </>
  )
}
