import { Grid } from '@material-ui/core'
import React, { useContext } from 'react'
import { IndividualVideo } from './IndividualVideo'
import { AppContext } from '../../AppContext';

export const Layout7 = (props) => {
  const {isMobile} = useContext(AppContext);
  if (isMobile) {
    return <div style={{ paddingRight: "2vh", paddingLeft: "2vh" }}>
    <Grid container>
      {props.videoStreams.map((vs, index) => {
        if (index !== 6) {
          return <Grid key={vs.assignedId} item sm={6} md={6} lg={6}>
            <IndividualVideo {...vs} {...props} />
          </Grid>
        }
        return <>
          <Grid key={vs.assignedId} item sm={3} md={3} lg={3}>
          </Grid>
          <Grid key={vs.assignedId} item sm={6} md={6} lg={6}>
            <IndividualVideo {...vs} {...props} />
          </Grid>
        </>
      }
      )}
    </Grid>
  </div>
  }
  return (
    <div style={{"paddingLeft": "3vw", "paddingRight": "3vw"}}>
      <Grid container justifyContent={"center"} alignItems={"center"} justify="center">
        {props.videoStreams.map(x => <Grid key={x.assignedId} item sm={12} md={3} lg={3}>
            <IndividualVideo {...props} {...x} />
          </Grid>)}
      </Grid>
    </div>
  )  
}
