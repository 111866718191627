import { CircularProgress } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { Layout1 } from './layouts/Layout1'
import { Layout2 } from './layouts/Layout2'
import { Layout3 } from './layouts/Layout3'
import { Layout4 } from './layouts/Layout4'
import { Layout5 } from './layouts/Layout5'
import { PinnedLayout } from './layouts/PinnedLayout'
import { SoloLayout } from './layouts/SoloLayout'
import { ScreenshareLayout } from './layouts/ScreenshareLayout'
import { Layout6 } from './layouts/Layout6'
import { Layout7 } from './layouts/Layout7'
import { Layout8 } from './layouts/Layoutt8'

export const Videos = (props) => {
  if(props.someoneIsSharing?.assignedId) {
    return <ScreenshareLayout {...props} />
  }

  if (props.solo) {
    return <SoloLayout {...props} />
  }

  // if (props.pinned) {
  //   return <PinnedLayout {...props} />
  // }

  if (props.layout === 2) {
    return <PinnedLayout {...props}  />
  }

  if (props.videoStreams.length === 1) {
    return <Layout1
      {...props}
    />
  }
  if (props.videoStreams.length === 2) {
    return <Layout2
      {...props}
    />
  }
  if (props.videoStreams.length === 3) {
    return <Layout3
      {...props}
    />
  }
  if (props.videoStreams.length === 4) {
    return <Layout4
      {...props}
    />
  }
  if (props.videoStreams.length === 5) {
    return <Layout5
      {...props}
    />
  }

  if (props.videoStreams.length === 6) {
    return <Layout6
      {...props}
    />
  }

  if (props.videoStreams.length === 7) {
    return <Layout7
      {...props}
    />
  }
  if (props.videoStreams.length >= 8) {
    return <Layout8
      {...props}
    />
  }

  return <div style={{ color: "white" }}>
    {/* {!loaded && <center><CircularProgress color="secondary" /> </center>} */}
  </div>
}
