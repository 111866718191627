import { Button } from '@material-ui/core'
import React from 'react'
import { BROADCASTER_MODE, JAMMER_MODE, PERFORMER_AND_BROADCASTER, PERFORMER_MODE, PERFORMER_WITH_BROADCASTER } from '../constants'

export const ModeButtons = ({broadcaster, setBroadcaster,  mode, setMode }) => {
  return (
    <div>
      <center>
        <Button style={{ marginLeft: "15px" }} onClick={() => {
          if(broadcaster && broadcaster === window.adapter?.id) {
            window.adapter.emit({
              type: "clearBroadcaster"
            })
            setBroadcaster(null)
          }
          setMode(JAMMER_MODE)}
        } variant="contained" color={mode === JAMMER_MODE ? `secondary` : 'primary'}>
          Jammer
      </Button>
        <Button style={{ marginLeft: "15px" }} onClick={() => {
          setMode(PERFORMER_MODE)
          if(broadcaster && broadcaster === window.adapter?.id) {
            window.adapter.emit({
              type: "clearBroadcaster"
            })
            setBroadcaster(null)
          }
        }} variant="contained" color={mode === PERFORMER_MODE ? `secondary` : 'primary'}>
          Performer
      </Button>
        <Button disabled={broadcaster && broadcaster !== window.adapter?.id} style={{ marginLeft: "15px" }} onClick={() => {
          window.adapter.emit({
            type: "setBroadcaster"
          })
          setBroadcaster(window.adapter?.id)
          setMode(BROADCASTER_MODE)}
          } variant="contained" color={mode === BROADCASTER_MODE ? `secondary` : 'primary'}>
          Broadcaster
      </Button>
      <Button disabled={broadcaster && broadcaster !== window.adapter?.id} style={{ marginLeft: "15px" }} onClick={() => {
          window.adapter.emit({
            type: "setBroadcaster"
          })
          setBroadcaster(window.adapter?.id)
          setMode(PERFORMER_AND_BROADCASTER)}
        } variant="contained" color={mode === PERFORMER_AND_BROADCASTER ? `secondary` : 'primary'}>
          Performer and Broadcaster
      </Button>
      <Button style={{ marginLeft: "15px" }} onClick={() => {
          setMode(PERFORMER_WITH_BROADCASTER)
          if(broadcaster && broadcaster === window.adapter?.id) {
            window.adapter.emit({
              type: "clearBroadcaster"
            })
            setBroadcaster(null)
          }
        }} variant="contained" color={mode === PERFORMER_WITH_BROADCASTER ? `secondary` : 'primary'}>
          Performer with broadcaster
      </Button>
      
      </center>
    </div>
  )
}
