import React, { useLayoutEffect, useState } from 'react';

export const AppContext = React.createContext({});
export const AppContextProvider = (props) => {

  function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
  }

  const [width, height] = useWindowSize();

  return <AppContext.Provider value={{
    isMobile: width < 960,
    isMid: width < 1300,
    width,
    height
  }}>
    {props.children}
  </AppContext.Provider>
}