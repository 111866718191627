import React, { useEffect, useContext } from 'react';
import { DeviceContext } from '../../DeviceContext';

export const VideoMute = ({ localVideoStreams, setVideoAssignedId, setLocalVideoStreams }) => {
  // useEffect(() => {
  //   console.log("_DEBUG_ on mount", localVideoStreams)
  // })
  const { userVideoDevices }= useContext(DeviceContext)

  const videoOn = localVideoStreams.length !== 0;
  
  const startCam = async () => {
    let cameraSet = false;
    userVideoDevices.map(device => {
      if (cameraSet) {
        return;
      }
      Object.keys(window.adapter.videoElProps).map(x => {
        const oldVideoConstraints = window.adapter.videoElProps[x]?.constraints?.video;
        if (!oldVideoConstraints) {
          return;
        }
        let oldVideoDeviceId = window.adapter.videoElProps[x]?.constraints?.video?.deviceId?.exact || window.adapter.videoElProps[x]?.constraints?.video?.deviceId
        if (device.deviceId !== oldVideoDeviceId) {
          window.adapter.createVideoProducer({ video: { deviceId: device.deviceId } }, null, null, device.label)
          cameraSet = true;
        }
      })
    })
    if (!cameraSet) {
      await window.adapter.createVideoProducer({ video: { deviceId: userVideoDevices[0].deviceId } }, null, null, userVideoDevices[0].label)
    }
  }
  return (
    <>
      <button onClick={() => {
        if (!localVideoStreams.length) {
          startCam();
          return;
        }
        localVideoStreams.map(vs => {
          window.adapter.destroyMediaProducer({
            assignedId: vs.assignedId,
          })
          vs.stream.getTracks().forEach(function (track) {
            track.stop();
          });
        });
        setVideoAssignedId(null);
        setLocalVideoStreams([]);
      }} className="video-footer-button" id="stopVideo"><img src={videoOn ? "/assets/icon-video-camera.svg" :  "/assets/icon-video-stop.svg" } /></button>
      <div className="video-footer-label">{videoOn ? "Stop Video" : "Start Video"}</div>
    </>
  )
}
