import { Button } from "@material-ui/core";
import React from "react";

export const Interact = ({ onFinish, userMediaReady }) => {
  return (
    <center>
      <div className="containerBig">
        <div className="vertical-center">
          {userMediaReady ? (
            <Button
              color="primary"
              variant="contained"
              size="large"
              onClick={onFinish}
            >
              Join room
            </Button>
          ) : (
            <p>Waiting for camera permissions...</p>
          )}
        </div>
      </div>
    </center>
  );
};
