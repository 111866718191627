import openSocket from "socket.io-client";
import { v4 as uuid } from 'uuid';
import { turnServerUrl } from "../config";

const mediasoupClient = require("mediasoup-client");

export default class CommunicationAdapter {
  constructor(backendUrl, room, metadata, producerParams, callback, webrtcType, recordProps) {
    this.webrtcType = webrtcType || "sfu";
    this.recordProps = recordProps;
    this.turnCreds = {
      username: "username",
      password: "password"
    }
    this.consumerTransport = undefined
    this.producerTransport = undefined
    this.audioTransmitting = false;
    this.filters = {};
    this.videoElProps = {};
    this.socket = undefined
    this.peerMediaHandler = undefined
    this.room = room;
    this.metadata = metadata;
    this.mediaProducers = [];
    this.mediaConsumers = [];
    this.producerParams = producerParams; // {audio: true, video: true}...
    this.callback = callback || (() => { });
    try {
      if (this._shouldConnectToWebrtc()) {
        this.webRTCDevice = new mediasoupClient.Device();
      }
    } catch (err) {
      console.log(err);
      console.log("error while loading mediasoup client device");
      // alert("Failed to load WebRTCDevice", err);
    }
    this.socket = openSocket(`${backendUrl}`, { transport: ['websocket'] });
    this.socket.on("disconnect", () => {
      
    })
    this.socket.on('connect_error', () => {
      
    });
    this.socket.on("connect", () => {
      this.socket.emit("initialize", {
        room,
        metadata,
      })
    })

    this.socket.on("identityAck", this.identityReceived.bind(this))
    this.socket.on("createdTransports", this.createdTransports.bind(this));
    this.socket.on("peerDisconnect", (data) => {
      this.mediaConsumers = this.mediaConsumers.filter(x => x.peerId !== data.peerId);
      this.callback({
        type: "disconnect",
        data
      })
    })
    this.socket.on("metaEvent", (data) => {
      this.callback({
        type: data.type,
        data
      })
    })
  }

  _shouldConnectToWebrtc() {
    return !this.recordProps.isRecorder || this.recordProps.connectToWebrtc
  }

  _getIceServers() {
    return new URLSearchParams(window.location.search).get("dontuseturn") ? [] : [{
      username: "",
      password: "",
      urls: `stun:${turnServerUrl}`
    }, {
      username: this.turnCreds.username,
      credential: this.turnCreds.password,
      urls: `turn:${turnServerUrl}`
    }]
    
  }

  async identityReceived(data) {
    this.id = data.id;

    this.turnCreds = data.turnCreds;
    try {
      if (this._shouldConnectToWebrtc()) {
        await this.webRTCDevice.load({
          routerRtpCapabilities: data.roomRTPCapabilities
        });
      }
    } catch (e) {
      console.error(e);
    }
    if (this._shouldConnectToWebrtc()) {
      this.socket.emit("createTransports", {
        numStreams: this.webRTCDevice.sctpCapabilities.numStreams,
        rtpCapabilities: this.webRTCDevice.rtpCapabilities
      })
    }

    this.callback({
      type: "isRecording",
      data: {value: data.isRecording}
    })
  }

  async createdTransports(transports) {
    this.producerTransport = await this.webRTCDevice.createSendTransport(
      {
        id: transports.producer.id,
        iceParameters: transports.producer.iceParameters,
        iceCandidates: transports.producer.iceCandidates,
        dtlsParameters: transports.producer.dtlsParameters,
        sctpParameters: transports.producer.sctpParameters,
        iceServers: this._getIceServers()
      });
    this.consumerTransport = await this.webRTCDevice.createRecvTransport(
      {
        id: transports.consumer.id,
        iceParameters: transports.consumer.iceParameters,
        iceCandidates: transports.consumer.iceCandidates,
        dtlsParameters: transports.consumer.dtlsParameters,
        sctpParameters: transports.consumer.sctpParameters,
        iceServers: this._getIceServers()
      });
    const self = this;
    this.consumerTransport.on('connect', async function ({ dtlsParameters }, callback, errback) {
      self.socket.emit('connectTransport', { direction: self.consumerTransport.direction, dtlsParameters: dtlsParameters })
      callback();
    })

    this.producerTransport.on('connect', async function ({ dtlsParameters }, callback, errback) {
      self.socket.emit('connectTransport', { direction: self.producerTransport.direction, dtlsParameters: dtlsParameters })
      callback();
    })

    this.producerTransport.on('produce', async function ({ kind, rtpParameters, appData }, callback, errback) {
      //Send producer data to server
      self.socket.emit('produceMedia', {
        producerOptions: {
          kind: kind,
          rtpParameters: rtpParameters,
          appData: appData
        }
      }, function (params) {
        self.mediaProducers = self.mediaProducers.map(producerProps => {
          if (producerProps.assignedId === params.assignedId) {
            return {
              ...producerProps,
              id: params.id
            }
          }
          return producerProps;
        })
        callback({ id: params.id });
      })
    })

    if (this.producerParams.audio) {
      this.createAudioProducer(
        { audio: false }
      );
    } else {}
    this.callback({
      type: "transportsCreated"
    })
  }
  async startRecording({ audioTrack , videoTrack}) {
    const assignedId = uuid();
    const videoProducer = await this.producerTransport.produce({
      track: videoTrack,
      appData: { assignedId, isRecording: true }
    })
    const audioProducer = await this.producerTransport.produce({
      track: audioTrack,
      appData: { assignedId, isRecording: true }
    })
  }
  async userIndicatedStartRecording() {
    this.socket.emit("startRecording")
  }
  async userIndicatedStopRecording() {
    this.socket.emit("stopRecording")
  }
}
