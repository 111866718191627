import './App.css';
import JamkazamVideoParent from "./components/JamkazamVideoParent";

import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

function App() {
  return (
    <div className="App" style={{  }}>
      <Router>
        <Switch>
            <Route path="/room/:roomId">
              <JamkazamVideoParent />
            </Route>
          </Switch>
        </Router>
    </div>
  );
}

export default App;
