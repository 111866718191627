import React from "react";
import { MuteIcon } from "../layouts/MuteIcon";
import { CameraSelector } from "./CameraSelector";
import { LayoutControls } from "./LayoutControls";
import { VideoMute } from "./VideoMute";
import {FIRST_NAME, NONE, FIRST_AND_LAST_NAME, NAME_AND_LOCATION} from "../constants";

export const Layout = ({
  labelMode, setLabelMode,
  audioAssignedId,
  //setVideoDevices,
  setVideoStreams,
  audioState,
  setAudioState,
  setVideoAssignedId,
  setFlipOutgoingOrientation,
  flipOutgoingOrientation,
  screenShareOn,
  session_creator,
  localVideoStreams,
  videoStreams,
  //videoDevices,
  toggleScreenshare,
  changeLayout,
  isRecording,
  setIsRecording,
  setLeftRotationHack,
  localAudioStreams,
  audioStreams,
  setRightRotationHack,
  setLocalVideoStreams,
  setLocalAudioStreams,
  setScreenShareOn,
  videoOn,
  setLayout,
  setSolo,
  solo,
  setPinned,
  layout,
  setVideoOn,
  showScreenshareButton,
  rotateLeft,
  rotateRight,
  flipLocal,
 }) => {
  return (
    <>
      {/* <div className="position-relative mt-4 mx-auto" style={{ height: "85%", "max-width": "85" }}>
        <div className="video-container ps-20">
          <div className="video-item">
            <img src="/assets/video-4.jpg" />

            <div className="video-label">
              Cameron Sellsmith
              <div className="video-label-location">Lincoln, Nebraska US</div>
            </div>

            <div className="video-menu" style={{ "text-align": "left" }} id="videoMenu4Control">
              <nav className="navbar navbar-dark bg-dark video-navbar">
                <button className="navbar-toggler video-navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#videoMenu4" area-controls="videoMenu4" aria-expanded="false" aria-label="Toggle navigation">
                </button>
              </nav>
              <nav className="navbar collapse navbar-dark bg-dark" id="videoMenu4">
                <ul className="navbar-nav">
                  <li className="nav-item"><a className="nav-link" href="#">Flip Horizontal</a></li>
                  <li className="nav-item"><a className="nav-link" href="#">Flip Vertical</a></li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div> */}


      <nav className="navbar navbar-dark bg-black fixed-bottom py-4 video-footer-controls" style={{ zIndex: 1000 }}>
        <div className="container justify-content-between align-items-start">
          <div className="navbar-brand" style={{ "marginTop": "-5px" }}><img alt="jamkazam" src="/assets/JK_Logo_white.png" width="200" /></div>
          <div className="navbar-expand">
            <ul className="navbar-nav">
              <li className="nav-item text-center">
                <VideoMute
                  localVideoStreams={localVideoStreams}
                  setVideoAssignedId={setVideoAssignedId}
                  setLocalVideoStreams={setLocalVideoStreams}
                />
              </li>
              <li className="nav-item text-center">
                <CameraSelector
                  flipLocal={flipLocal}
                  setVideoStreams={setVideoStreams}
                  flipOutgoingOrientation={flipOutgoingOrientation}
                  setVideoAssignedId={setVideoAssignedId}
                  setLocalVideoStreams={setLocalVideoStreams}
                  localVideoStreams={localVideoStreams}
                />
              </li>
              <li className="nav-item text-center">
                <LayoutControls
                  layout={layout}
                  setLeftRotationHack={setLeftRotationHack}
                  setRightRotationHack={setRightRotationHack}
                  setLayout={setLayout}
                  videoStreams={videoStreams}
                  solo={solo} 
                  setSolo={setSolo}
                />
              </li>
              <li className="nav-item text-center">
                <button className="video-footer-button" id="labels"><img src="/assets/icon-video-labels.svg" /></button>
                <div className="video-footer-label">Labels</div>
                <nav className="navbar video-footer-menu p-0">
                  <ul className="navbar nav flex-column align-items-start p-0 video-dropdown-menu">
                    <li className="nav-item p-1 pe-2 m-0 " onClick={() => setLabelMode(NONE)}>
                      <div className="d-flex justify-content-between"><a className="nav-link p-0" href="#">
                      <img src={labelMode === NONE  ? "/assets/icon-video-check.svg" : "/assets/transparent.png"} className="d-inline-block me-2" width="16" />
                        None</a></div>
                    </li>
                    <li className="nav-item p-1 pe-2 m-0 " onClick={() => setLabelMode(FIRST_NAME)}>
                      <div className="d-flex justify-content-between"><a className="nav-link p-0" href="#">
                      <img src={labelMode === FIRST_NAME ? "/assets/icon-video-check.svg" : "/assets/transparent.png"} className="d-inline-block me-2" width="16" />
                        First Name</a></div>
                    </li>
                    <li className="nav-item p-1 pe-2 m-0 " onClick={() => setLabelMode(FIRST_AND_LAST_NAME)}>
                      <div className="d-flex justify-content-between"><a className="nav-link p-0" href="#">
                      <img src={labelMode === FIRST_AND_LAST_NAME  ? "/assets/icon-video-check.svg" : "/assets/transparent.png"} className="d-inline-block me-2" width="16" />
                        First &amp; Last Name</a></div>
                    </li>
                    <li className="nav-item p-1 pe-2 m-0 " onClick={() => setLabelMode(NAME_AND_LOCATION)}>
                      <div className="d-flex justify-content-between">
                        <a className="nav-link p-0 text-white" href="#">
                          <img src={labelMode === NAME_AND_LOCATION  ? "/assets/icon-video-check.svg" : "/assets/transparent.png"} className="d-inline-block me-2" width="16" />
                          Name &amp; Location
                        </a>
                      </div>
                    </li>
                    <li className="video-dropdown-menu-caret p-0 m-0 text-center"></li>
                  </ul>
                </nav>
              </li>
              {(showScreenshareButton) && <li className="nav-item text-center">
                <button onClick={toggleScreenshare} className="video-footer-button" id="shareScreen">
                  {screenShareOn ? <img src="/assets/icon-video-stop.svg" /> : <img src="/assets/icon-video-sharescreen.svg" />}
                </button>
                <div className="video-footer-label">{screenShareOn ? "Stop Share Screen" : "Screen share"}</div>
              </li>}
            </ul>
          </div>
          {/* <div className="navbar-expand">
            <ul className="navbar-nav">
              <li className="nav-item text-center">
                <MuteIcon
                  audioAssignedId={audioAssignedId}
                  setVideoDevices={setVideoDevices}
                  setLocalAudioStreams={setLocalAudioStreams}
                  audioState={audioState}
                  peerId={"local"}
                  setAudioState={setAudioState}
                />
              </li>
            </ul>
          </div> */}
        </div>
      </nav>
    </>
  )
}